.App {
  text-align: center;
}

.quote-fade-in {
  opacity: 0;
  transform: translateX(-100%); /* Move the element outside the screen from the left */
  animation: quote-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes quote-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}

.head-fade-in {
  opacity: 0;
  transform: translateY(-100%); /* Move the element outside the screen from the left */
  animation: head-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes head-fade-in {
  to {
    opacity: 1;
    transform: translateY(0); /* Bring the element back inside the screen */
  }
}

.vis-fade-in {
  opacity: 0;
  transform: translateX(-100%); /* Move the element outside the screen from the left */
  animation: vis-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes vis-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}

.mis-fade-in {
  opacity: 0;
  transform: translateX(-100%); /* Move the element outside the screen from the left */
  animation: mis-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */

}

@keyframes mis-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}

.slide-in-out {
  opacity: 0;
  transform: translateX(-100%); /* Start from the left side */
  animation: slide-in-out 5s ease-in-out infinite; /* Apply the slide-in animation infinitely */
}

@keyframes slide-in-out {
  0%, 100% {
    opacity: 0;
    transform: translateX(-100%); /* Start and end off-screen to the left */
  }
  25%, 75% {
    opacity: 1;
    transform: translateX(0); /* Slide in and stay in position */
  }
}

.mix-fade-in {
  opacity: 0;
  transform: translateY(-100%); /* Move the element outside the screen from the left */
  animation: mix-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes mix-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}

.img-fade-in {
  opacity: 0;
  transform: translateX(-100%); /* Move the element outside the screen from the left */
  animation: img-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes img-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}

.desc-fade-in {
  opacity: 0;
  transform: translateY(-100%); /* Move the element outside the screen from the left */
  animation: desc-fade-in 1.5s ease-in-out forwards; /* Apply the fade-in animation */
}

@keyframes desc-fade-in {
  to {
    opacity: 1;
    transform: translateX(0); /* Bring the element back inside the screen */
  }
}